<template>
  <div v-editable="blok" class="zip-search">
    <input
      ref="inputRef"
      aria-label="Enter a ZIP Code to search for shifts."
      class="zip-search__input"
      type="text"
      :value="inputValue"
      :placeholder="blok.label ?? 'ZIP Code'"
      name="zipcode"
      @keyup.enter="handleSubmit"
    />
    <button type="submit" class="zip-search__submit" @click="handleSubmit">
      {{ blok.cta_text ?? 'Explore Shifts' }}
    </button>
    <span v-if="error" class="zip-search__error">{{
      blok.error_text ?? 'Please enter a valid Zip code'
    }}</span>
  </div>
</template>

<script lang="ts" setup>
import { ZipSearchBlokProps } from '@/types';

// Props
const props = withDefaults(
  defineProps<{
    blok: ZipSearchBlokProps;
    initialzip?: string;
    onzipchange?: (zip: string) => void;
  }>(),
  {
    initialzip: '',
    onzipchange: undefined,
  }
);

const route = useRoute();
const inputRef = ref<HTMLInputElement>();
const inputValue = ref(props.initialzip);
const error = ref(false);

const normalizeZip = (value: String) => value.replace(/[\D]*/g, '').slice(0, 5);

const handleSubmit = () => {
  if (inputRef.value) {
    const normalizedZip = normalizeZip(inputRef.value.value);

    if (normalizedZip?.length === 5) {
      error.value = false;
      inputValue.value = normalizedZip;

      if (props.onzipchange) {
        props.onzipchange(normalizedZip);
      }
      navigateTo({
        path: '/shifts/results',
        query: {
          ...route.query,
          zipcode: normalizedZip,
        },
      });
    } else {
      error.value = true;
    }
  }
};
</script>

<style lang="scss">
.zip-search {
  background-color: var(--white);
  color: var(--dark-gray) !important;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  max-width: 360px;
  min-height: 50px;
  padding-block: 4px;
  padding-inline: 6px;
  position: relative;
  width: 100%;

  @media all and #{$mq-m-min} {
    padding-block: 16px;
    padding-inline: 24px;
  }

  &__input {
    appearance: none;
    border: none;
    font-size: 1rem;
    min-width: 50px;
    width: 100%;

    @media all and #{$mq-m-min} {
      font-size: 1.125rem;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--dark-gray);
    }
  }

  &__submit {
    @include primary-button(removeTheme);
    background-color: var(--dark-gray) !important;
  }

  &__error {
    color: var(--red);
    font-size: 0.875rem;
    inset-block-start: 100%;
    inset-inline-start: 0;
    margin-block-start: 8px;
    position: absolute;

    @media all and #{$mq-m-min} {
      font-size: 1.125rem;
    }
  }
}
</style>
